import { useCallback } from 'react'
import { useUpdateCustomerEmailMutation } from '@sevenrooms/core/api'
import { FormProvider, useFieldArray, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, LoaderButton, Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { appMessages } from '../../app.locales'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { useCustomerPaymentContext } from '../../hooks'
import { emailsCollectionStepMessages } from './EmailsCollectionStep.locales'
import { type EmailsCollectionStepForm, useEmailsCollectionStepForm } from './EmailsCollectionStep.zod'

export function EmailsCollectionStep() {
  const { formatMessage } = useLocales()
  const { push } = useNavigation()
  const { venues: defaultVenues, customerPaymentSessionId, isConsolidatedInvoice } = useCustomerPaymentContext()
  const [updateCustomerEmail, { isLoading }] = useUpdateCustomerEmailMutation()
  const emailsCollectionStepFormSchema = useEmailsCollectionStepForm()
  const { field, ...formMethods } = useForm(emailsCollectionStepFormSchema, { defaultValues: { venues: defaultVenues }, mode: 'onChange' })
  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods
  const { fields: venues } = useFieldArray(field, 'venues')

  const onClickBackButton = useCallback(() => {
    push(routes.captureCustomerPayment.paymentStep, { query: { customerPaymentSessionId } })
  }, [push, customerPaymentSessionId])

  const onClickNextButton = useCallback(
    async (formData: EmailsCollectionStepForm) => {
      try {
        await updateCustomerEmail({
          customerPaymentSessionId,
          isConsolidatedInvoice,
          listOfVenueEmails: formData.venues
            .filter(item => item.emails)
            .map(({ id, emails }) => ({ id, emails: (emails as string).split(',').map(email => email.trim()) })),
        }).unwrap()
        push(routes.captureCustomerPayment.submitted)
      } catch (e) {
        notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
      }
    },
    [customerPaymentSessionId, formatMessage, isConsolidatedInvoice, push, updateCustomerEmail]
  )

  return (
    <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
      <FormProvider {...formMethods}>
        <Text data-test={CustomerPaymentsTestId.emailsCollectionStepDescription} textStyle="h1">
          {formatMessage(emailsCollectionStepMessages.header)}
        </Text>
        <SecondaryText>{formatMessage(emailsCollectionStepMessages.infoSecondLine)}</SecondaryText>
        <VStack data-test={CustomerPaymentsTestId.emailsCollectionStepEmailsList} spacing="s">
          {venues.map((venue, index) => (
            <Label key={venue.id} primary={venue.label}>
              <FormInput
                field={field.prop(`venues.${index}.emails`)}
                placeholder={formatMessage(emailsCollectionStepMessages.invoiceRecipientPlaceholder)}
              />
            </Label>
          ))}
        </VStack>
        <VStack spacing="m" pt="s">
          <Button data-test={CustomerPaymentsTestId.emailsCollectionStepBackButton} variant="secondary" onClick={onClickBackButton}>
            {formatMessage(commonMessages.back)}
          </Button>
          <LoaderButton
            data-test={CustomerPaymentsTestId.emailsCollectionStepNextButton}
            loading={isLoading}
            disabled={!isValid}
            onClick={handleSubmit(onClickNextButton)}
          >
            {formatMessage(commonMessages.next)}
          </LoaderButton>
        </VStack>
      </FormProvider>
    </CustomerExperienceLayout>
  )
}
