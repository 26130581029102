import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { appMessages } from '../../app.locales'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'

export function ErrorPage() {
  const { formatMessage } = useLocales()
  return (
    <CustomerExperienceLayout variant="error">
      <Text data-test={CustomerPaymentsTestId.errorPageHeader} textStyle="h1">
        {formatMessage(appMessages.error)}
      </Text>
      <Text data-test={CustomerPaymentsTestId.errorPageDescription} fontSize="m">
        {formatMessage(appMessages.errorDescription)}
      </Text>
      <Button
        data-test={CustomerPaymentsTestId.errorPageBackButton}
        variant="primary"
        href={routes.captureCustomerPayment.contractInfoStep.path}
      >
        {formatMessage(commonMessages.back)}
      </Button>
    </CustomerExperienceLayout>
  )
}
