import { useCallback } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { BACS } from '@sevenrooms/core/ui-kit/core/Adyen'
import { Button, FormInput, Label, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { SupportEmail } from '../SupportEmail'
import { paymentStepMessages } from './PaymentStep.locales'
import type { PaymentFlowStepProps } from './types'

export function PaymentOneToken({
  renderAdyenComponent,
  onSubmit,
  field,
  isValid,
  isLoading,
  displayControls,
  paymentMethod,
  renderBACSControls,
  pennyTestLabel,
}: PaymentFlowStepProps) {
  const { formatMessage } = useLocales()
  const { push } = useNavigation()

  const onPaymentCompleted = useCallback(() => {
    push(routes.captureCustomerPayment.submitted)
  }, [push])

  const isBACS = paymentMethod === BACS
  return (
    <>
      {displayControls && !isBACS && (
        <>
          <Text data-test={CustomerPaymentsTestId.paymentStepOneTokenDescription} fontSize="m">
            {formatMessage(paymentStepMessages.paymentConsolidatedInvoice)}
          </Text>
          <Text fontSize="s">{formatMessage(paymentStepMessages.paymentConsolidatedInvoiceDetails, { email: <SupportEmail /> })}</Text>
          {pennyTestLabel}
        </>
      )}
      {renderAdyenComponent(onPaymentCompleted)}
      {displayControls && !isBACS && (
        <>
          <Label
            primary={formatMessage(paymentStepMessages.invoiceRecipient)}
            secondary={formatMessage(paymentStepMessages.invoiceRecipientLabel)}
          >
            <FormInput inputMode="email" field={field.prop('emails')} />
          </Label>
          <Button
            data-test={CustomerPaymentsTestId.paymentStepOneTokenBackButton}
            variant="secondary"
            disabled={isLoading}
            href={routes.captureCustomerPayment.contractInfoStep.path}
          >
            {formatMessage(commonMessages.back)}
          </Button>
          <LoaderButton
            data-test={CustomerPaymentsTestId.paymentStepOneTokenNextButton}
            disabled={!isValid}
            loading={isLoading}
            onClick={onSubmit}
          >
            {formatMessage(commonMessages.submit)}
          </LoaderButton>
        </>
      )}
      {isBACS &&
        renderBACSControls(onPaymentCompleted, () => {
          push(routes.captureCustomerPayment.contractInfoStep)
        })}
    </>
  )
}
