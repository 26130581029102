import { useCallback } from 'react'
import { useUpdateAdyenSessionMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Adyen } from '@sevenrooms/core/ui-kit/core/Adyen'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { appMessages } from '../../app.locales'
import { useCustomerPaymentContext, useSettingsContext } from '../../hooks'
import { paymentStepMessages } from '../PaymentStep/PaymentStep.locales'

export function PaymentFinal() {
  const { formatMessage } = useLocales()
  const { push } = useNavigation()
  const { query } = useDestination(routes.captureCustomerPayment.paymentFinal)
  const { isPayByParent, isConsolidatedInvoice, selectedVenueIds } = useCustomerPaymentContext()
  const [updateAdyenSession] = useUpdateAdyenSessionMutation()

  const { sfdcVenueId } = query

  const onPaymentError = useCallback(() => {
    push(routes.captureCustomerPayment.error)
  }, [push])

  const onPaymentCompleted = useCallback(
    (result: { resultCode: string; sessionData: string }) => {
      const getSelectedIndex = (sfdcVenueId: string) => selectedVenueIds.indexOf(sfdcVenueId)
      const selectedIndex = getSelectedIndex(sfdcVenueId as string)

      updateAdyenSession({ resultCode: 'INPUT_CAPTURED', adyenSessionModelId: query.adyenSessionModelId })
        .unwrap()
        .catch(() => {
          push(routes.captureCustomerPayment.error)
        })

      if (result.resultCode === 'Error' || result.resultCode === 'Refused') {
        notify({ type: 'error', content: formatMessage(appMessages.genericServerError) })
        const newSfdcVenueId = selectedVenueIds[selectedIndex]
        if (newSfdcVenueId) {
          push(routes.captureCustomerPayment.paymentStep, {
            query: { sfdcVenueId: newSfdcVenueId, customerPaymentSessionId: query.customerPaymentSessionId },
          })
        } else {
          push(routes.captureCustomerPayment.paymentStep, {
            // eslint-disable-next-line
            // @ts-ignore
            query: { customerPaymentSessionId: query.customerPaymentSessionId },
          })
        }
        return
      }
      if (isPayByParent && !isConsolidatedInvoice) {
        push(routes.captureCustomerPayment.emailsCollectionStep, {
          query: { customerPaymentSessionId: query.customerPaymentSessionId },
        })
      } else if (isPayByParent || isConsolidatedInvoice) {
        push(routes.captureCustomerPayment.submitted, {})
      } else if (selectedIndex !== undefined && selectedIndex + 1 < (selectedVenueIds?.length || 0)) {
        const newSfdcVenueId = selectedVenueIds[selectedIndex + 1]
        if (newSfdcVenueId) {
          push(routes.captureCustomerPayment.paymentStep, {
            query: { sfdcVenueId: newSfdcVenueId, customerPaymentSessionId: query.customerPaymentSessionId },
          })
        }
      } else {
        push(routes.captureCustomerPayment.submitted)
      }
    },
    [
      formatMessage,
      isConsolidatedInvoice,
      isPayByParent,
      push,
      query.customerPaymentSessionId,
      query.adyenSessionModelId,
      selectedVenueIds,
      sfdcVenueId,
      updateAdyenSession,
    ]
  )

  const settings = useSettingsContext()
  return (
    <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
      <Text textStyle="h1">{formatMessage(paymentStepMessages.header)}</Text>
      <Adyen
        clientKey={settings.clientKey}
        sessionId={query.sessionId}
        holderNameRequired
        billingAddressRequired
        environment={settings.environment}
        onPaymentCompleted={onPaymentCompleted}
        onError={onPaymentError}
        redirectResult={query.redirectResult}
      />
    </CustomerExperienceLayout>
  )
}
