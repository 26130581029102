import type { CustomerPaymentSessionData } from '@sevenrooms/core/domain'
import { createContext } from '@sevenrooms/core/utils'

export const defaultCustomerPaymentSessionContext = {
  customerPaymentSessionId: '',
  isConsolidatedInvoice: false,
  isPayByParent: false,
  venues: [],
  selectedVenueIds: [],
}

export const [CustomerPaymentSessionProvider, useCustomerPaymentContext] = createContext<CustomerPaymentSessionData>()
