import { useCallback, useMemo } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { BACS } from '@sevenrooms/core/ui-kit/core/Adyen'
import { Button, FormInput, Label, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { useCustomerPaymentContext } from '../../hooks'
import { paymentStepMessages } from './PaymentStep.locales'
import type { PaymentFlowStepProps } from './types'

export function PaymentPerVenue(props: PaymentFlowStepProps & { sfdcVenueId: string }) {
  const {
    renderAdyenComponent,
    onSubmit,
    field,
    isValid,
    isLoading,
    displayControls,
    onReset,
    sfdcVenueId,
    paymentMethod,
    renderBACSControls,
    pennyTestLabel,
  } = props
  const { formatMessage } = useLocales()
  const { push } = useNavigation()
  const { selectedVenueIds, venues, customerPaymentSessionId } = useCustomerPaymentContext()
  const selectedIndex = selectedVenueIds.indexOf(sfdcVenueId)
  const venueName = useMemo(() => venues.find(venue => venue.id === sfdcVenueId)?.label, [sfdcVenueId, venues])

  const onGoToVenue = useCallback(
    (isBack: boolean, index: number) => {
      onReset?.()
      const newSfdcVenueId = selectedVenueIds[index + (isBack ? -1 : 1)]
      if (newSfdcVenueId) {
        push(routes.captureCustomerPayment.paymentStep, { query: { sfdcVenueId: newSfdcVenueId, customerPaymentSessionId } })
      }
    },
    [onReset, selectedVenueIds, push, customerPaymentSessionId]
  )

  const onClickBackButton = useCallback(() => {
    if (selectedIndex - 1 >= 0) {
      onGoToVenue(true, selectedIndex)
    } else {
      push(routes.captureCustomerPayment.venuesSelectionStep, { query: { customerPaymentSessionId } })
    }
  }, [onGoToVenue, push, selectedIndex, customerPaymentSessionId])

  const onPaymentCompleted = useCallback(() => {
    if (selectedIndex + 1 < selectedVenueIds.length) {
      onGoToVenue(false, selectedIndex)
    } else {
      push(routes.captureCustomerPayment.submitted)
    }
  }, [selectedIndex, selectedVenueIds, onGoToVenue, push])

  const isBACS = paymentMethod === BACS

  return (
    <>
      {displayControls && !isBACS && (
        <>
          <Text data-test={CustomerPaymentsTestId.paymentStepPerVenueDescription} fontSize="m">
            {formatMessage(paymentStepMessages.paymentLabelPerVenue)}
          </Text>
          {pennyTestLabel}
        </>
      )}
      <Text data-test={CustomerPaymentsTestId.paymentStepPerVenueName} textStyle="h2">
        {venueName}
      </Text>
      {renderAdyenComponent(onPaymentCompleted)}
      {displayControls && !isBACS && (
        <>
          <Label
            primary={formatMessage(paymentStepMessages.invoiceRecipientPerVenue)}
            secondary={formatMessage(paymentStepMessages.invoiceRecipientLabelPerVenue)}
          >
            <FormInput
              inputMode="email"
              field={field.prop('emails')}
              placeholder={formatMessage(paymentStepMessages.invoiceRecipientPlaceholderPerVenue)}
            />
          </Label>
          <Button
            data-test={CustomerPaymentsTestId.paymentStepPerVenueBackButton}
            variant="secondary"
            disabled={isLoading}
            onClick={onClickBackButton}
          >
            {formatMessage(commonMessages.back)}
          </Button>
          <LoaderButton
            data-test={CustomerPaymentsTestId.paymentStepPerVenueNextButton}
            disabled={!isValid}
            loading={isLoading}
            onClick={onSubmit}
          >
            {formatMessage(commonMessages.submit)}
          </LoaderButton>
        </>
      )}
      {isBACS && renderBACSControls(onPaymentCompleted, onClickBackButton)}
    </>
  )
}
