import { useCallback, useEffect, useState } from 'react'
import { useCreateAdyenSessionMutation, useUpdateAdyenSessionMutation, useUpdateCustomerEmailMutation } from '@sevenrooms/core/api'
import { FormProvider, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Adyen, type AdyenComponent, type PaymentResult, type BeforeSubmitData, BACS } from '@sevenrooms/core/ui-kit/core/Adyen'
import { Button, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { Box, Loader, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { CustomerExperienceLayout } from '@sevenrooms/core/ui-kit/vx-layout'
import { CustomerPaymentsTestId } from '../../CustomerPayments.testIds'
import { useCustomerPaymentContext, useSettingsContext } from '../../hooks'
import { ErrorDialog } from '../ErrorDialog'
import { errorDialogMessages } from '../ErrorDialog/ErrorDialog.locales'
import { PaymentOneToken } from './PaymentOneToken'
import { PaymentOneTokenNonConsolidated } from './PaymentOneTokenNonConsolidated'
import { PaymentPerVenue } from './PaymentPerVenue'
import { paymentStepMessages } from './PaymentStep.locales'
import { type RecipientsForm, useRecipientsForm } from './PaymentStep.zod'
import type { PaymentFlowStepProps } from './types'

export function PaymentStep() {
  const { formatMessage } = useLocales()
  const { matchQuery, push } = useNavigation()
  const [paymentMethod, setPaymentMethod] = useState('')
  const [isAdyenSubmitting, setIsAdyenSubmitting] = useState(false)
  const [isAdditionalInfoAsked, setIsAdditionalInfoAsked] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string | null>(null)
  const [adyenComponent, setAdyenComponent] = useState<AdyenComponent>()
  const [isAdyenFormValid, setIsAdyenFormValid] = useState(false)
  const { isPayByParent, isConsolidatedInvoice, customerPaymentSessionId } = useCustomerPaymentContext()
  const [updateCustomerEmail] = useUpdateCustomerEmailMutation()
  const [createAdyenSession, { isLoading: isAdyenSessionCreating, reset: resetCreatedAdyenSession, data: adyenSessionData }] =
    useCreateAdyenSessionMutation()
  const [updateAdyenSession] = useUpdateAdyenSessionMutation()
  const { sfdcVenueId } = matchQuery(routes.captureCustomerPayment.paymentStep) ?? {}
  const isOneTokenForAll = isPayByParent || isConsolidatedInvoice

  useEffect(() => {
    // CX-105 this useEffect will be removed in scope of separate ticket
    createAdyenSession({
      customerPaymentSessionId,
      isOneTokenForAll,
      sfdcVenueId,
    })
      .unwrap()
      .catch(() => {
        push(routes.captureCustomerPayment.error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfdcVenueId])

  const settings = useSettingsContext()

  const recipientsFormSchema = useRecipientsForm()
  const { field, ...formMethods } = useForm(recipientsFormSchema, { mode: 'onChange' })
  const { handleSubmit } = formMethods

  const resetPageState = useCallback(() => {
    setIsAdditionalInfoAsked(false)
    setIsAdyenSubmitting(false)
    setPaymentMethod('')
  }, [])

  const onSubmitAdyen = useCallback(() => {
    setIsAdyenSubmitting(true)
    adyenComponent?.submit()
  }, [adyenComponent])

  const onSubmit = useCallback(
    async ({ emails }: RecipientsForm) => {
      try {
        if (emails) {
          await updateCustomerEmail({
            customerPaymentSessionId,
            isConsolidatedInvoice,
            listOfVenueEmails: [{ id: sfdcVenueId, emails: emails.split(',').map(email => email.trim()) }],
          }).unwrap()
        }
        onSubmitAdyen()
      } catch (e) {
        setDialogMessage(formatMessage(errorDialogMessages.emailErrorBody))
      }
    },
    [onSubmitAdyen, updateCustomerEmail, customerPaymentSessionId, isConsolidatedInvoice, sfdcVenueId, formatMessage]
  )

  const onPaymentCompleted = useCallback(
    (onCompleted: () => void) =>
      async ({ resultCode }: PaymentResult) => {
        if (!adyenSessionData) {
          return
        }
        try {
          await updateAdyenSession({
            resultCode: 'INPUT_CAPTURED',
            adyenSessionModelId: adyenSessionData.id,
          }).unwrap()
        } catch (e) {
          setDialogMessage(formatMessage(errorDialogMessages.body))
        }
        if (resultCode === 'Error' || resultCode === 'Refused') {
          setDialogMessage(formatMessage(errorDialogMessages.adyenRefusedBody))
        } else {
          onCompleted()
        }
        resetPageState()
      },
    [adyenSessionData, formatMessage, resetPageState, updateAdyenSession]
  )

  const onPaymentError = useCallback(() => {
    if (!adyenSessionData) {
      return
    }
    setDialogMessage(formatMessage(errorDialogMessages.body))
    resetPageState()
  }, [adyenSessionData, formatMessage, resetPageState])

  const beforeSubmit = useCallback(
    async (data: BeforeSubmitData, component, actions: { resolve: (data: BeforeSubmitData) => void }) => {
      setPaymentMethod(data.paymentMethod.type)
      if (!adyenSessionData) {
        return
      }
      const isBACS = data.paymentMethod.type === BACS
      if (isBACS) {
        try {
          await updateAdyenSession({
            resultCode: 'INPUT_CAPTURED',
            adyenSessionModelId: adyenSessionData.id,
          }).unwrap()
        } catch (e) {
          setDialogMessage(formatMessage(errorDialogMessages.body))
        }
      }
      actions.resolve(data)
    },
    [adyenSessionData, formatMessage, updateAdyenSession]
  )

  const props: PaymentFlowStepProps = {
    renderAdyenComponent: (onCompleted: () => void) =>
      adyenSessionData ? (
        <Box pb="s">
          <Adyen
            clientKey={settings.clientKey}
            sessionData={adyenSessionData.sessionData}
            sessionId={adyenSessionData.sessionId}
            environment={settings.environment}
            holderNameRequired
            billingAddressRequired
            onMount={setAdyenComponent}
            onPaymentCompleted={onPaymentCompleted(onCompleted)}
            onError={onPaymentError}
            onChange={setIsAdyenFormValid}
            onActionHandled={() => setIsAdditionalInfoAsked(true)}
            onSelect={setIsAdyenFormValid}
            beforeSubmit={beforeSubmit}
          />
        </Box>
      ) : null,
    renderBACSControls: (onCompleted: () => void, onClickBackButton: () => void) => (
      <>
        <Text data-test={CustomerPaymentsTestId.paymentStepPerVenueBACSlabel} fontSize="m">
          {formatMessage(paymentStepMessages.confirmDownloadedBACSdoc)}
        </Text>
        <Button
          data-test={CustomerPaymentsTestId.paymentStepPerVenueBackButton}
          variant="secondary"
          onClick={() => {
            resetPageState()
            onClickBackButton()
          }}
        >
          {formatMessage(commonMessages.back)}
        </Button>
        <LoaderButton
          data-test={CustomerPaymentsTestId.paymentStepPerVenueNextButton}
          onClick={() => {
            resetPageState()
            onCompleted()
          }}
        >
          {formatMessage(commonMessages.next)}
        </LoaderButton>
      </>
    ),
    field,
    customerPaymentSessionId,
    isValid: isAdyenFormValid,
    isLoading: isAdyenSubmitting,
    displayControls: !isAdditionalInfoAsked,
    pennyTestLabel: (
      <SecondaryText data-test={CustomerPaymentsTestId.pennyTestLabel} fontSize="s">
        {formatMessage(paymentStepMessages.pennyLabelForPayment)}
      </SecondaryText>
    ),
    onSubmit: handleSubmit(onSubmit),
    paymentMethod,
  }

  const onReset = useCallback(() => {
    resetCreatedAdyenSession()
  }, [resetCreatedAdyenSession])

  if (isAdyenSessionCreating) {
    return (
      <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path}>
        <Box pt="xxl" pb="xxl">
          <Loader size="m" />
        </Box>
      </CustomerExperienceLayout>
    )
  }

  return (
    <CustomerExperienceLayout baseUrl={routes.captureCustomerPayment.contractInfoStep.path} wide={isAdditionalInfoAsked}>
      <FormProvider {...formMethods}>
        <Text data-test={CustomerPaymentsTestId.paymentStepHeader} textStyle="h1">
          {formatMessage(paymentStepMessages.header)}
        </Text>
        {isPayByParent && isConsolidatedInvoice && <PaymentOneToken {...props} />}
        {isPayByParent && !isConsolidatedInvoice && <PaymentOneTokenNonConsolidated {...props} onSubmit={onSubmitAdyen} />}
        {sfdcVenueId && <PaymentPerVenue {...props} onReset={onReset} sfdcVenueId={sfdcVenueId} />}
        <Window active={!!dialogMessage}>
          {dialogMessage && <ErrorDialog message={dialogMessage} onClose={() => setDialogMessage(null)} />}
        </Window>
      </FormProvider>
    </CustomerExperienceLayout>
  )
}
